import React, { useEffect, useState } from 'react';
import logoImage from '../images/logo/vareprint.webp';
import cartImage from '../images/cart-01.webp';
import profileImage from '../images/profile.webp';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';
import jwtDecode from 'jwt-decode'; // Correct import for jwt-decode
import { useUser } from './user'; // Adjust the import path as needed

const baseURL = process.env.REACT_APP_API_BASE_URL;

function Nav() {
  
  const [isCartOpen, setCartOpen] = useState(false);
  const [isProfileOpen, setProfileOpen] = useState(false);
  const navigate = useNavigate();
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [carts, setCarts] = useState([]);
  const [cartvalue, setCartValue] = useState({
    total_withoutsgst: 0,
    total_gstprice: 0,
    total: 0 
  });
  const [designData, setDesignData] = useState([]);
  const [userData, setUserData] = useState([]);
  const { userId, loading, login, logout, cookieUser, sessionUser  } = useUser();

  useEffect(() =>{
    if(sessionUser){
    axios.get(`${baseURL}/get-user-details/${userId}`)
    .then(response => {
      const userData = response.data.user || [];
      setUserData(userData); // Set the cart data array
     
    })
  }
  },[userId]);

  useEffect(() => {
    if (!userId) return;

    // Fetch cart details based on userId
    axios.get(`${baseURL}/cart/${userId}`)
      .then(response => {
        const cartdata = response.data.cartdetails || [];
        setCarts(cartdata); // Set the cart data array
        setCartValue({
          total_withoutsgst: response.data.total_withoutsgst.toFixed(2),
          total_gstprice: response.data.total_gstprice.toFixed(2),
          total: response.data.total.toFixed(2),
        });
        const designIds = [...new Set(cartdata.map(cart => cart.did))];
        // Fetch design data for each unique design ID
        fetchDesignData(designIds);
      })
      .catch(error => {
        console.error('Error fetching cart details:', error);
      });
  }, [userId]);



  useEffect(() => {
    if (query.length > 2) {
      // Fetch data from an API
      const fetchSuggestions = async () => {
        try {
          const response = await axios.get(`${baseURL}/search-product/?q=${query}`);
          setSuggestions(response.data);
        } catch (error) {
          console.error('Error fetching suggestions:', error);
        }
      };

      fetchSuggestions();
    } else {
      setSuggestions([]);
    }
  }, [query]);

  // Function to fetch design data for each design ID
  const fetchDesignData = (designIds) => {
    designIds.forEach(did => {
      axios.get(`${baseURL}/design-fetch/${did}`)
        .then(response => {
          const designdata = response.data.designdetails || [];
          setDesignData(prevData => [
            ...prevData,
            ...designdata.map(design => ({
              ...design,
              id: did // Attach the cart ID to each design object
            }))
          ]);
        })
        .catch(error => {
          console.error(`Error fetching design details for design ID ${did}:`, error);
        });
    });
  };

  const handleCartClick = () => {
    setCartOpen(!isCartOpen);
    setProfileOpen(false); // Close profile if open
  };


  const handleProfileClick = () => {
    setProfileOpen(!isProfileOpen);
    setCartOpen(false);
  };

  const handleInputChange = (event) => {
    setQuery(event.target.value);
  };


    // Function to handle deletion of an item from the cart
    const handleDelete = (cart_id) => {
   

      axios
        .delete(`${baseURL}/cart/delete/${cart_id}`)
        .then((response) => {
          if (response.status === 200) {
            // Filter out the deleted cart item from state
            setCarts((prevCarts) => prevCarts.filter((cart) => cart.cart_id !== cart_id));
            fetchCartDetails(); 
           
          }
        })
        .catch((error) => {
          console.error('Error deleting item from cart:', error);
        
        });
    };
    const fetchCartDetails = () => {
      // Fetch cart details based on user ID or session
      axios.get(`${baseURL}/cart/${userId}`)
        .then(response => {
          const cartdata = response.data.cartdetails || [];
          setCarts(cartdata); // Set the cart data array
          setCartValue({
            total_withoutsgst: response.data.total_withoutsgst.toFixed(2),
            total_gstprice: response.data.total_gstprice.toFixed(2),
            total: response.data.total.toFixed(2),
          });
        })
        .catch(error => {
          console.error('Error fetching cart details:', error);
        });
    };
    const handleLogout = () => {
      logout(); // Assuming logout clears the session or token
      window.location.href = '/login-page'; // Redirect to login page after logout
  };
  
  return (
    <React.Fragment>
      <header>
        {/* <p> user id is {userId}</p> */}
        <div className='row header'>
          <div className='col-md-4 top1'>
            <input 
              type="text" 
              className="form-control searchbar" 
              name="search" 
              value={query}
              placeholder="Search Categories, Products and much more.."
              onChange={handleInputChange} 
            />
            <ul className='search-list-ul'>
            {
                    suggestions.map((suggestion) => (
                        <Link 
                            to={`/design/${suggestion.pid}`} 
                            key={suggestion.pid} // Use a unique key for each item
                        >
                            <li className='search-list-ul-li'>
                                {suggestion.pname}
                            </li>
                        </Link>
                    ))
                }
            </ul>
          </div>
          <div className='col-md-4 logo_div'>
            <Link to={`/`}>
              <img src={logoImage} className="logo_image" alt="VR Print Logo" />
            </Link>
          </div>
          <div className='col-md-4 profile_div'>
            <img 
              src={cartImage} 
              className="cart_img" 
              alt="Cart" 
              onClick={handleCartClick} 
              data-toggle="collapse" 
            
              role="button" 
              aria-expanded={isCartOpen ? "true" : "false"} 
              aria-controls="collapseExample1"
            />

            
            <div className={`collapse multi-collapse ${isCartOpen ? 'show' : ''}`} id="collapseExample1">
              <div className="card card-body">
                <div className='container cart-container'>
                  <div className='row'>
                    <div className='col-sm-7 cart_div1'>Cart</div>
                    <div className='col-sm-5 cart_div2'>
                      <Link to='/' type='button' className='continue_shopping1'>CONTINUE SHOPPING</Link>
                    </div>
                  </div>
                  <hr />
                  {carts.length > 0 ? (
  <>
    <div className='row div3'>
      <div className='col-sm-4 cart_div'>Product</div>
      <div className='col-sm-3 cart_div'>Quantity</div>
      <div className='col-sm-3 cart_div'>Price</div>
      <div className='col-sm-2 cart_div'></div>
    </div>
    <hr />

    {carts.map((cart, index) => (
      <div key={index}>
        {designData
          .filter(design => design.id === cart.did) // Filter design data by cart.did
          .map((design, idx) => (
            <div className='row cart-data-row' key={idx}>
              <div className='col-sm-4 div3'>
                <p className='product_cart'>{design.pname}</p>
              </div>
              <div className='col-sm-3 div3'>
                <p>{cart.quantity}</p>
              </div>
              <div className='col-sm-3 div3'>
                <p>&#8377; {parseFloat(cart.withoutgstprice).toFixed(2)}</p>
              </div>
              <div className='col-sm-2 div3'>
                <p className='remove_cart' onClick={() => handleDelete(cart.cart_id)}>X</p>
              </div>
            </div>
          ))}
        <hr />
      </div>
    ))}

    {/* Subtotal, Tax, and Total Section */}
    <div className='row cart-data-row'>
      <div className='col-sm-7 div4'>
        <p>SUBTOTAL</p>
      </div>
      <div className='col-sm-5 div3'>
        <p>&#8377; {cartvalue.total_withoutsgst}</p>
      </div>

      <div className='col-sm-7 div4'>
        <p>Tax</p>
      </div>
      <div className='col-sm-5 div3'>
        <p>&#8377; {cartvalue.total_gstprice}</p>
      </div>

      <div className='col-sm-7 div4'>
        <p>Total</p>
      </div>
      <div className='col-sm-5 div3'>
        <p>&#8377; {cartvalue.total}</p>
      </div>
      <hr />
    </div>

    {/* Checkout Button */}
    <div className='col-sm-12 cart-button-row'>
      <Link to={`/cart-page`}>
        <button className='cart-button'>PROCEED TO CHECKOUT</button>
      </Link>
    </div>
  </>
) : (
  <div className='empty-cart'>
    <p className='empty-cart-title'>Your cart is empty. <br/> Add something to it!</p>
  </div>
)}


                
                </div>
              </div>
            </div>

            { userData.profile_pic ? (
    <img 
      src={`${baseURL}/images/${userData.profile_pic}`} 
      className='profile_img' 
      alt="Profile" 
      onClick={handleProfileClick} 
      data-toggle="collapse" 
    
      role="button" 
      aria-expanded={isProfileOpen ? "true" : "false"} 
      aria-controls="collapseExample2"
    />
  ) : (
    <img 
      src={profileImage} 
      className="profile_img" 
      alt="Profile" 
      onClick={handleProfileClick} 
      data-toggle="collapse" 
     
      role="button" 
      aria-expanded={isProfileOpen ? "true" : "false"} 
      aria-controls="collapseExample2"
    />
)}

           
            <div className={`collapse multi-collapse ${isProfileOpen ? 'show' : ''}`} id="collapseExample2">
              <div className="card card-body">
              {cookieUser ? (
                <Link to="/login-page"><p className='profile-label'>Login</p> </Link>
) : (
    <>
        <p className='profile-label'>{userData.userName}</p>
        <Link to={`/profile`} ><p className='profile-label'>Profile</p></Link>
        <p className='profile-label' onClick={handleLogout}>Logout</p>
    </>
)}
              </div>
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
}

export default Nav;
