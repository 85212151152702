import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import Nav from './nav';
import Footer from './footer';
import { Link, useNavigate } from 'react-router-dom';
import { useUser } from './user';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import {jwtDecode} from 'jwt-decode'; // Note the updated import

const LoginRegister = () => {
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const { userId, login } = useUser();

  const navigate = useNavigate();
  const [loginEmail, setLoginEmail] = useState('');
  const [loginPassword, setLoginPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [finalpassword, setFinalPassword] = useState('');
  const [identifier, setIdentifier] = useState('');
  const [contact, setContact] = useState('');
  const [otp, setOtp] = useState('');
  const [message, setMessage] = useState('');
  const [message1, setMessage1] = useState('');
  const [registerStep, setRegisterStep] = useState(1);
  const [isPrivacyPolicyAccepted, setIsPrivacyPolicyAccepted] = useState(false);


  useEffect(() => {
    document.title = "Vareprint | Login"; // Change the title dynamically
  }, []);

  
  const handleLogin = async (e) => {
    e.preventDefault();
    
    if (!isPrivacyPolicyAccepted) {
        setMessage('Please accept the Terms & Conditions and Privacy Policy');
        return;
    }

    try {
        const response = await login(identifier, password, userId);
        
        if (response.success) {
          const redirectPath='/cart-page';
          window.location.href = redirectPath;
        } else {
            setMessage(response.message || 'Login failed');
        }
    } catch (error) {
        setMessage(error.message || 'Login failed');
    }
  };

  const generateGuestId = () => {
    const randomNumber = Math.floor(100000 + Math.random() * 900000);
    return `G-${randomNumber}`;
  };

  const GuestUser = async () => {
    const guest = generateGuestId();
    const response = await axios.post(`${baseURL}/insert-guest`, { guest });
    // navigate(`/checkout/${guest}`);
    window.location.href = `/checkout/${guest}`;

  };

  const handleGenerateOtp = async () => {
    if (!email && !contact) {
      setMessage1('*Email and contact are required');
      return; // Prevent further execution
    }
  
    if (!email) {
      setMessage1('*Email is required');
      return; // Prevent further execution
    }
  
    if (!contact) {
      setMessage1('*Contact is required');
      return; // Prevent further execution
    }
  
    
      const response = await axios.post(`${baseURL}/generate-otp`, { firstName, lastName, email, contact });
      setMessage1(response.data.message); // Set the response message
  
      if (response.data.success) {
        setRegisterStep(2); // Proceed to the next step if successful
      }
    
  };
  
  const handleRegister = async () => {
    try {
      const response = await axios.post(`${baseURL}/verify-otp`, { firstName, lastName, email, contact, otp });
      setMessage1(response.data.message);
      if (response.data.success) {
        setRegisterStep(3);
      }
    } catch (error) {
      console.error('Error during registration:', error);
      setMessage1('Error during registration');
    }
  };

  const handlePassword = async () => {
    try {
      const response = await axios.post(`${baseURL}/save-user`, { firstName, lastName, finalpassword, email, contact });
      setMessage1(response.data.message);
      if (response.data.success) {
        setRegisterStep(4);
        navigate('/login-page');
      }
    } catch (error) {
      console.error('Error during registration:', error);
      setMessage1('Error during registration');
    }
  };

  const handleLoginSuccess = async (credentialResponse) => {
    try {
      const decodedData = jwtDecode(credentialResponse.credential);
      const { email, name } = decodedData;
       const isGoogleLogin = true;
      

      // Check if user already exists
      const checkResponse = await axios.post(`${baseURL}/check-email-exists`, { email });
      if (checkResponse.data.exists) {
      
        
        const loginResponse = await login(email, null, userId, isGoogleLogin);  // Assuming your backend accepts email/password
        if (loginResponse.success) {
          setMessage("Login successful!");
          window.location.href = `/cart-page`;
        } else {
          setMessage(loginResponse.message || "Login failed");
        }
      } else {
        // If user doesn't exist, register and then log them in
        const registerResponse = await axios.post(`${baseURL}/register-gmail`, { email, name });
        if (registerResponse.data.success) {
          const loginResponse = await login(email, null, userId, isGoogleLogin);
          if (loginResponse.success) {
            setMessage("Registration and login successful!");
            window.location.href = `/cart-page`;
          } else {
            setMessage(loginResponse.message || "Login after registration failed");
          }
        } else {
          setMessage(registerResponse.data.message || "Registration failed");
        }
      }
    } catch (error) {
      console.error("Error with Google login:", error);
      setMessage("An error occurred during Google login.");
    }
  };

  const handleLoginFailure = () => {
    console.log("Login failed");
  };

  return (
    <React.Fragment>
      <Nav />
      {/* <p>{userId}</p> */}
      <div className='container'>
        <div className='row login-container-row'>
          <div className='col-sm-6 sign_in_div'>
            <div>
              <p className='sign_in'>SIGN IN</p>
              <p className='signintitle'>Sign in with your email and password</p>
              <hr />
              {message && <p className='notification-login'>{message}</p>}
              <form onSubmit={handleLogin}>
                <div className="form-group">
                  <label>Email or Phone Number:</label>
                  <input
                    type="text"
                    className="form-control"
                    value={identifier}
                    onChange={(e) => setIdentifier(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Password:</label>
                  <input
                    type="password"
                    className="form-control"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
              
                <p className='instruction-login'>
                  <input 
                    type='checkbox' 
                    checked={isPrivacyPolicyAccepted} 
                    onChange={() => setIsPrivacyPolicyAccepted(!isPrivacyPolicyAccepted)} 
                    required
                  /> 
                    &nbsp;   I accept your <Link to="/terms-condition" className="term-priv">Terms & Conditions</Link> and <Link to="/privacy-policy" className="term-priv">Privacy Policy</Link>.
                </p>
                <hr />
                <div className='form-group row login-button-div'>
                  <div className='col-sm-6'>
                    <button className='continue_shopping' type="submit">SIGN IN</button>
                  </div>
                  <div className='col-sm-6 google-login-div' >
                  <GoogleOAuthProvider clientId="963405453586-cceukdgmtgrmj7utl78tqvsusafb573v.apps.googleusercontent.com">
      <div className="App">
     
        <GoogleLogin
          onSuccess={handleLoginSuccess}
          onError={handleLoginFailure}
        />
      </div>
    </GoogleOAuthProvider>
                 
                  </div>
                </div>
              </form>
              <h4>Continue as a guest</h4>
              <p>You can create an account after checkout for a faster experience next time.</p>
              <button className='continue_shopping' onClick={GuestUser}>CHECKOUT AS A GUEST</button>
            </div>
          </div>

          <div className='col-sm-6 sign_up_div'>
            {registerStep === 1 && (
              <div>
                <p className='sign_in'>CREATE YOUR ACCOUNT</p>
                <hr />
                <div className='register-div'>
                <div className='form-group row'>
                  <label>First Name:</label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter First Name'
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
                <div className='form-group row'>
                  <label>Last Name:</label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter Last Name'
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
                <div className='form-group row'>
                  <label>Email Address:</label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter Email Address' required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className='form-group row'>
                  <label>Contact:</label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter Contact Number'
                    value={contact} required
                    onChange={(e) => setContact(e.target.value)}
                  />
                </div>
                <div className='form-group row'>
                  <div className='col-sm-12 generate-button-row'>
                    <button className='continue_shopping' onClick={handleGenerateOtp}>Generate OTP</button>
                  </div>
                </div>
                <p className='message-error'>{message1}</p>
              </div>
              </div>
            )}

            {registerStep === 2 && (
              <div>
                <p>VERIFY OTP</p>
                <hr />
                <div className='form-group row'>
                  <label>OTP:</label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter OTP'
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                  />
                </div>
                <div className='form-group row'>
                  <div className='col-sm-12'>
                    <button className='continue_shopping' onClick={handleRegister}>VERIFY</button>
                  </div>
                </div>
                <p>{message1}</p>
              </div>
            )}

            {registerStep === 3 && (
              <div>
                <p>SET PASSWORD</p>
                <hr />
                <div className='form-group row'>
                  <label>Password:</label>
                  <input
                    type='password'
                    className='form-control'
                    placeholder='Enter Password'
                    value={finalpassword}
                    onChange={(e) => setFinalPassword(e.target.value)}
                  />
                </div>
                <div className='form-group row'>
                  <div className='col-sm-12'>
                    <button className='continue_shopping' onClick={handlePassword}>REGISTER</button>
                  </div>
                </div>
                <p>{message1}</p>
              </div>
            )}

{registerStep === 4 && (
              <div>
               
               
                
                <p className='account-created-note'>* Account Created Successfully.</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default LoginRegister;
