import React, { useEffect } from "react";

const ProfileList = () => {
  useEffect(() => {
    // Initialize DataTable
    window.$("#profileTable").DataTable(); // Use global jQuery (`window.$`)
  }, []);

  return (
    <div className="profile-list">
      <table id="profileTable" className="display">
        <thead>
          <tr>
            <th>Product</th>
            <th>Qty</th>
            <th>Price</th>
            <th>Status</th>
            <th>Track Order</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Sample Product</td>
            <td>1</td>
            <td>₹100.00</td>
            <td>Pending</td>
            <td>
              <a href="track-order-url">Track</a>
            </td>
          </tr>
          <tr>
            <td>Another Product</td>
            <td>2</td>
            <td>₹200.00</td>
            <td>Shipped</td>
            <td>
              <a href="track-order-url">Track</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ProfileList;
