import React, { useEffect, useState } from 'react';
import Nav from './nav';
import Footer from './footer';
import { Link, useNavigate } from 'react-router-dom';
import { useUser } from './user'; // Adjust the import path as needed
import axios from 'axios';

import 'bootstrap/dist/css/bootstrap.min.css';

const baseURL = process.env.REACT_APP_API_BASE_URL;

const Profile = () => {
  const navigate = useNavigate();
  const { userId, sessionUser } = useUser();


  

  const [userData, setUserData] = useState({
    userId: userId,
    firstName: '',
    lastName: '',
    address: '',
    postcode: '',
    city: '',
    state: '',
    country: '',
    email: '',
    newPassword: '',
    confirmPassword: '',
    profile_pic:null,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null); // For storing the uploaded image
  const [imagePreview, setImagePreview] = useState(`${baseURL}/images/profile-icon.jpg`); // Default profile image
  useEffect(() => {
    if (!sessionUser) {
    navigate('/login-page');
    }
  }, [sessionUser]);

 
    useEffect(() => {
      // Initialize DataTable
      window.$("#profileTable").DataTable(); // Use global jQuery (`window.$`)
    }, []);

  useEffect(() => {
    if (sessionUser) {
      axios.get(`${baseURL}/get-user-details/${userId}`)
        .then(response => {
          const userData = response.data.user || {};
          setUserData(userData);
          setImagePreview(userData.profile_pic ? `${baseURL}/images/${userData.profile_pic}` : `${baseURL}/images/profile-icon.jpg`);
          setLoading(false);
        })
        .catch(error => {
          setError(error);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [userId, sessionUser]);

  const handleTextChange = (e) => {
    const { name, value } = e.target;
    setUserData(prevUser => ({
      ...prevUser,
      [name]: value,
    }));
    if (name === 'postcode' && value.length === 6) {
      handlePostcode(value);
    }
  };

  const handlePostcode = async (postcode) => {
    try {
      const response = await axios.get(`${baseURL}/postcode/${postcode}`);
      if (response.data && Array.isArray(response.data.code) && response.data.code.length > 0) {
        const data = response.data.code[0];
        if (data && data.postcode && data.city && data.state) {
          setUserData(prevUser => ({
            ...prevUser,
            postcode: data.postcode,
            city: data.city,
            state: data.state,
            country: data.country  // Keep existing value if not provided
          }));
        } else {
          console.error('Unexpected data structure:', data);
        }
      } else {
        console.error('No data found or unexpected response format:', response.data);
      }
    } catch (error) {
      console.error('Error fetching postcode data:', error);
    }
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file); // Store the selected file

      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result); // Update image preview with base64 string

        // Update userData with the selected file as profile_pic
        setUserData(prevUserData => ({
          ...prevUserData,
          profile_pic: file // Save the file object in userData
        }));
      };

      reader.readAsDataURL(file); // Read file and convert it to a base64 string for preview
    }
  };


  const handleSubmit = (e) => {
    e.preventDefault();
   
    axios.post(`${baseURL}/update-user-details-profile`, userData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(response => {
        // Handle success
        alert('User details updated successfully');
      })
      .catch(error => {
        // Handle error
        alert('Failed to update user details');
        console.error('Error updating user details:', error);
      });

    
  };

  const updatePassword = (e) => {
  // Update password if new password and confirm password match
  if (userData.newPassword && userData.newPassword === userData.confirmPassword) {
    axios.post(`${baseURL}/update-password`, {
      userId,
      newPassword: userData.newPassword,
    })
    .then(response => {
      // Handle success
      alert('Password updated successfully');
      setUserData(prevUser => ({
        ...prevUser,
        newPassword: '',
        confirmPassword: '',
      }));
    })
    .catch(error => {
      // Handle error
      alert('Failed to update password');
      console.error('Error updating password:', error);
    });
  } else if (userData.newPassword) {
    alert('New password and confirm password do not match');
  }
};
  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <React.Fragment>
      <Nav />
      <div className='container'>
        <ul className='filter_ul'>
          <li className='top_ul'><Link className='top_ul' to={`/`}>Home</Link></li>
        </ul>
      </div>
      <div className='container'>
        <div className='row'>
          <div className='col-sm-4 profile-div'>
          <img 
                src={imagePreview} 
                className='profilfe-img' 
                alt="Profile" 
              />

       <br />    
<input 
              type='file' 
              className='form-control update-profile-pic' 
              name="profile_pic" 
              onChange={handleFileChange}
            />
          </div>
          <div className="col-sm-8">
            <div className="row">
              <div className="col-sm-12 toggle-div">
                <p className='collapse-title'
                  data-toggle="collapse" 
                  data-target="#collapseContent2" 
                  aria-expanded="false" 
                  aria-controls="collapseContent2"
                >
                  Shipping Details
                </p>
                <div className="collapse collapse-profile-div" id="collapseContent2">
                  <div className="card card-body">
                    <form onSubmit={handleSubmit}>
                      <div className='form-group row'>
                        <label>First Name</label>
                        <input
                          type='text'
                          className='form-control'
                          name='firstName'
                          value={userData.firstName}
                          onChange={handleTextChange}
                        />
                      </div>
                      <div className='form-group row'>
                        <label>Last Name</label>
                        <input
                          type='text'
                          className='form-control'
                          name='lastName'
                          value={userData.lastName}
                          onChange={handleTextChange}
                        />
                      </div>
                      <div className='form-group row'>
                        <label>Address</label>
                        <textarea
                          className='form-control'
                          name='address'
                          value={userData.address}
                          onChange={handleTextChange}
                        />
                      </div>
                      <div className='form-group row'>
                        <div className='col-sm-6'>
                          <label>Postcode</label>
                          <input
                            type='text'
                            className='form-control'
                            name='postcode'
                            value={userData.postcode}
                            onChange={handleTextChange}
                          />
                        </div>
                        <div className='col-sm-6'>
                          <label>City</label>
                          <input
                            type='text'
                            className='form-control'
                            name='city'
                            value={userData.city}
                            onChange={handleTextChange} readOnly
                          />
                        </div>
                      </div>
                      <div className='form-group row'>
                        <div className='col-sm-6'>
                          <label>State</label>
                          <input
                            type='text'
                            className='form-control'
                            name='state'
                            value={userData.state}
                            onChange={handleTextChange} readOnly
                          />
                        </div>
                        <div className='col-sm-6'>
                          <label>Country</label>
                          <input
                            type='text'
                            className='form-control'
                            name='country'
                            value={userData.country}
                            onChange={handleTextChange} readOnly
                          />
                        </div>
                        <div className='col-sm-12 button-profile-div'>
                          <button type='submit' className='profile-btn-update'>Update</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 toggle-div">
                <p className='collapse-title'
                  data-toggle="collapse" 
                  data-target="#collapseContent" 
                  aria-expanded="false" 
                  aria-controls="collapseContent"
                >
                  Update Password
                </p>
                <div className="collapse collapse-profile-div" id="collapseContent">
                  <div className="card card-body">
                    <form onSubmit={updatePassword}>
                      <div className='form-group row'>
                        <label>Email</label>
                        <input
                          type='text'
                          className='form-control'
                          name='email'
                          value={userData.email}
                          onChange={handleTextChange}
                          readOnly
                        />
                      </div>
                      <div className='form-group row'>
                        <label>New Password</label>
                        <input
                          type='password'
                          className='form-control'
                          name='newPassword'
                          value={userData.newPassword}
                          onChange={handleTextChange}
                        />
                      </div>
                      <div className='form-group row'>
                        <label>Confirm New Password</label>
                        <input
                          type='password'
                          className='form-control'
                          name='confirmPassword'
                          value={userData.confirmPassword}
                          onChange={handleTextChange}
                        />
                      </div>
                      <div className='col-sm-12 button-profile-div'>
                        <button type='submit' className='profile-btn-update'>Update</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 toggle-div">
                <p className='collapse-title'
                  data-toggle="collapse" 
                  data-target="#collapseContent3" 
                  aria-expanded="false" 
                  aria-controls="collapseContent3"
                >
                  Order History
                </p>
                <div className="collapse collapse-profile-div" id="collapseContent3">
                  <div className="card card-body">
                  <div className="profile-list2">
                  <table id="profileTable" className="display">
        <thead>
          <tr>
            <th>Product</th>
            <th>Qty</th>
            <th>Price</th>
            <th>Status</th>
            <th>Track Order</th>
          </tr>
        </thead>
        <tbody>
         
        </tbody>
      </table>
</div>

                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default Profile;
